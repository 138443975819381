<template>
  <div class="image__container">
    <img ref="image" :src="require(`@/assets/${src}.png`)" />
    <h4>{{ src }}</h4>
  </div>
</template>

<script>
import Cropper from "cropperjs"

export default {
  name: "ImageCropper",
  props: {
    src: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cropper: {},
      destination: {},
      image: {},
    }
  },
  mounted() {
    this.image = this.$refs.image
    this.cropper = new Cropper(this.image, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
    })
  },
}
</script>

<style scoped>
img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
</style>
