<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h3 class="bold">Industry</h3>
    </b-card-header>
    <b-card-body v-if="view_industries && view_industries.length !== 0">
      <b-button
        v-for="(industry, index) in view_industries"
        :key="index"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-2 mr-2"
      >
        {{ industry }}
      </b-button>
    </b-card-body>
  </b-card>
</template>
<script>
import { BButton, BCard, BCardBody, BCardHeader } from "bootstrap-vue"
import store from "@/store"
import Ripple from "vue-ripple-directive"

export default {
  components: {
    BCard,
    BButton,
    BCardHeader,

    BCardBody,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      experiencesData: [],
      view_industries: [],
    }
  },

  created() {},
  mounted() {
    this.getExperience()
  },
  methods: {
    getExperience() {
      const industryData = store.state.auth.user.candidate.experiences
      const industryArray = []
      industryData.forEach((element) => {
        industryArray.push(...JSON.parse(element.industry))
      })
      this.view_industries = Array.from(
        new Set(industryArray.map((item) => item.name))
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.progress__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
