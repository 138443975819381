<template>
  <b-card no-body class="card-apply-job pb-2 pt-1 space-between">
    <b-card-header class="pb-1">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <!-- profile picture -->
          <div class="position-relative">
            <div class="profile-img-container d-flex align-items-center">
              <div class="profile-img">
                <b-img
                  v-if="user.image"
                  v-b-modal.modal-image
                  src="./../../assets/images/avatars/2-small.png"
                  rounded
                  fluid
                  alt="profile photo"
                />
                <b-avatar
                  v-else
                  v-b-modal.modal-image
                  size="80"
                  variant="light-primary"
                  src="./../../assets/images/avatars/2-small.png"
                  rounded
                  fluid
                  alt="profile photo"
                >
                  <feather-icon icon="UserIcon" size="45" />
                </b-avatar>
              </div>
            </div>
          </div>
        </b-media-aside>
        <b-media-body class="pl-2">
          <h4 class="mb-0">
            {{ user.name }}
          </h4>
          <span v-if="user.candidate.title" class="bold">Designation: </span>
          <span v-if="user.candidate.title">{{ user.candidate.title }}</span>
          <p v-if="user.address">
            {{ user.city }},{{ user.state }}, {{ user.country }}
          </p>
          <br v-if="!user.address" />
          <small v-if="updated_at" class="text-muted">{{ updated_at }}</small>
        </b-media-body>
      </b-media>
      <div class="edit__icon basic__editIcon">
        <b-img
          v-b-modal.modal-basic
          class=""
          src="./../../assets/images/logo/bold.png"
        />
      </div>
    </b-card-header>
    <!-- <b-card-body>
      <div class="row pt-2 pb-2">
        <div class="progress-wrapper col-lg-12 col-sm-12">
          <b-card-text class="mb-1">
            Profile Completed: <b>{{ completed_profile }}%</b>
          </b-card-text>
          <b-progress
            v-if="completed_profile < 50"
            :value="completed_profile"
            max="100"
            variant="warning"
            animated
          />
          <b-progress
            v-if="completed_profile > 50 && completed_profile < 100"
            :value="completed_profile"
            max="100"
            variant="primary"
            animated
          />
          <b-progress
            v-if="completed_profile === 100"
            :value="completed_profile"
            max="100"
            variant="success"
            animated
          />
          <div class="progress__status">
            <b v-if="completed_profile < 50"> Weak </b>
            <p v-else>Weak</p>
            <b v-if="completed_profile > 50 && completed_profile < 100">
              Strong
            </b>
            <p v-else>Strong</p>
            <b v-if="completed_profile === 100"> Excellent </b>
            <p v-else>Excellent</p>
          </div>
        </div>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
      >
        Create Resume for my Profile
      </b-button>
    </b-card-body> -->
    <!-- modal basic edit -->
    <b-modal
      id="modal-basic"
      ref="modal_basic"
      centered
      title="Edit Basic Profile Details"
      cancel-title="Close"
      ok-title="Save Details"
      size="lg"
      @ok="handleSaveDetails"
    >
      <div class="pl-5 pr-5 ml-3 mr-3">
        <b-form-group label="First Name" label-for="fName">
          <b-form-input
            id="fName"
            v-model="detailsForm.first_name"
            placeholder="Enter First Name"
          />
        </b-form-group>
        <b-form-group label="Middle Name" label-for="designation">
          <b-form-input
            id="designation"
            v-model="detailsForm.middle_name"
            placeholder="Enter Middle Name"
          />
        </b-form-group>
        <b-form-group label="Last Name" label-for="designation">
          <b-form-input
            id="designation"
            v-model="detailsForm.last_name"
            placeholder="Enter Last Name"
          />
        </b-form-group>
      </div>
    </b-modal>

    <!-- modal image upload -->
    <b-modal
      id="modal-image"
      centered
      title="Upload Profile Picture"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      size="lg"
      @ok="handleProfileImg"
    >
      <form ref="form" @submit.stop.prevent="submitProfileImg">
        <div class="pl-5 pr-5 ml-3 mr-3">
          <b-col lg="8" class="px-xl-2 mx-auto">
            <div class="flex__column">
              <b-avatar class="m-2" :src="url" size="100px" rounded />
              <b-button
                variant="primary"
                class="mt-5"
                @click="$refs.refInputEl.click()"
              >
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @change="onFileChange"
                />
                <span class="d-none d-sm-inline px-1"
                  >Upload Profile Picture</span
                >
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
            </div>
          </b-col>
        </div>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BCardBody,
  BCardHeader,
  BCardText,
  BCollapse,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BNavbar,
  BNavbarToggle,
  BNavItem,
  BProgress,
  BTabs,
  VBModal,
} from "bootstrap-vue"
import moment from "moment"
import Ripple from "vue-ripple-directive"

import store from "@/store/index"
import ImageCropper from "../shared-component/ImageCropper.vue"

export default {
  components: {
    BCard,
    BCol,
    BButton,
    BBadge,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BFormDatepicker,
    BFormGroup,
    BFormRadioGroup,
    BFormSelect,
    BFormTextarea,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BCard,
    BTabs,
    BButton,
    BImg,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BProgress,
    // custom croper
    ImageCropper,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      form: {
        image: null,
      },
      detailsForm: {
        first_name: store.state.auth.user.first_name,
        middle_name: store.state.auth.user.middle_name,
        last_name: store.state.auth.user.last_name,
      },

      updated_at: moment(store.state.auth.user.candidate.updated_at).format(
        "lll"
      ),

      url: null,
      type: "",
      refInputEl: null,
    }
  },
  computed: {
    user() {
      return store.state.auth.user
    },
    completed_profile() {
      let total = 0
      if (this.user.candidate.has_basic_details) {
        total += 25
      }
      if (this.user.candidate.has_qualification) {
        total += 25
      }
      if (this.user.candidate.has_job_details) {
        total += 25
      }
      if (this.user.candidate.has_job_selected) {
        total += 25
      }
      return total
    },
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]

      this.form.image = file
      this.url = URL.createObjectURL(file)
    },
    handleProfileImg(bvModalEvent) {
      // Prevent modal from closing
      // bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitProfileImg()
    },
    handleSaveDetails(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitBasicDetails()
      this.$nextTick(() => {
        this.$refs.modal_basic.toggle("#toggle-btn")
      })
    },
    submitProfileImg() {
      store.dispatch("candidateProfile/profileImage", this.form)
    },
    submitBasicDetails() {
      store.dispatch("candidateProfile/updateResume", this.detailsForm)
    },
  },
}
</script>
<style lang="scss" scoped>
.progress__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.edit__icon :hover {
  cursor: pointer;
}

.basic__editIcon {
  margin-top: -80px;
}
.flex__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
