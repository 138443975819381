<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h3 class="bold">Language</h3>
      <div class="edit__icon">
        <b-img
          v-if="user_languages && user_languages.length !== 0"
          v-b-modal.modal-lang
          src="./../../assets/images/logo/bold.png"
        />
        <b-button
          v-else
          v-b-modal.modal-lang
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
        >
          Add Language
        </b-button>
      </div>
    </b-card-header>
    <b-card-body v-if="user_languages">
      <div v-for="(language, i) in user_languages" :key="i" class="my-2">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
        >
          {{ language.name }}
        </b-button>
        <span class="ml-1">{{ language.skills }}</span>
        <br />
      </div>
    </b-card-body>
    <!-- modal vertical center -->
    <b-modal
      id="modal-lang"
      centered
      title="Edit/Add Language"
      cancel-title="Close"
      ok-only
      size="lg"
      ok-title="Save Details"
      @ok="handleLanguagesForm"
    >
      <div class="form">
        <b-row v-for="(language, i) in language.languages" :key="i">
          <b-col
            lg="12"
            class="d-flex justify-content-between align-items-center mb-3"
          >
            <div class="language_name">
              <h5 class="my-auto">
                {{ language.name }}
              </h5>
              <b-img
                v-b-modal.modal-lang
                class="ml-2"
                src="./../../assets/images/icons/Icon.png"
                @click="removeLang(i)"
              />
            </div>
            <div>
              <b-form-group
                v-model="language.skills"
                style="display: inline-flex"
              >
                <b-form-radio v-model="language.skills" value="Beginner"
                  >Beginner</b-form-radio
                >
                <b-form-radio v-model="language.skills" value="Intermediate"
                  >Intermediate</b-form-radio
                >
                <b-form-radio v-model="language.skills" value="Expert"
                  >Expert</b-form-radio
                >
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <h6 class="ml-1">Add a Language</h6>
        <b-input-group class="mx-1">
          <b-form-input
            id="add_language"
            v-model="addLanguage"
            placeholder="Enter Language"
          />
          <b-input-group-append>
            <b-button variant="primary" @click="handleAddLanguage">
              Add
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import store from "@/store"
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormCheckbox,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BFormCheckboxGroup,
  BFormGroup,
  BFormRadio,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

export default {
  components: {
    BFormCheckboxGroup,
    BFormRadio,
    BRow,
    BCol,
    BCard,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCardHeader,
    BFormInput,
    BCardBody,
    BImg,
    BFormCheckbox,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      value: 25,
      selectLangOne: [],
      selectLangTwo: [],
      selectLangThree: [],
      addLanguage: "",
      language: {
        action: "language",
        languages: store.state.auth.user.candidate.languages || [],
      },
    }
  },

  computed: {
    user_languages() {
      return store.state.auth.user.candidate.languages
    },
  },
  methods: {
    handleAddLanguage() {
      console.log(this.language)
      const newLanguage = {
        name: this.addLanguage,
        skills: [],
      }
      this.language.languages.push(newLanguage)
      this.addLanguage = ""
    },
    removeLang(indexed) {
      const newArray = this.language.languages.filter(
        (item, index) => index !== indexed
      )
      this.language.languages = newArray
    },
    handleLanguagesForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitLanguagesForm()
    },
    submitLanguagesForm() {
      store.dispatch("candidateProfile/updateResume", this.language)
      this.$nextTick(() => {
        this.$bvModal.hide("modal-lang")
      })
      // this.clearState()
    },
  },
}
</script>
<style lang="scss" scoped>
.progress__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.language_name {
  width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form {
  margin: 20px 120px;
}
</style>
