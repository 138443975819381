<!-- eslint-disable global-require -->
<template>
  <b-card no-body class="card-payment">
    <b-card-body>
      <div class="space-between">
        <h3 class="bold">Instant Connect</h3>
        <div class="edit__icon">
          <b-img
            v-b-modal.modal-instant-connect
            class="ml-auto"
            src="./../../assets/images/logo/bold.png"
          />
        </div>
      </div>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Preferable Option">
            <div class="profile-img">
              <b-img :src="priority_app_image" rounded fluid />
              <span class="ml-1 text-large font-weight-bold">{{
                priority_app_number
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Other Options" class="mb-2">
            <b-img
              v-for="(other, index) in othersImage"
              :key="index"
              :src="other"
              rounded
              fluid
              alt="profile photo"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        class="d-flex justify-content-between align-items-center web_presence"
      >
        <h4>Web Presence</h4>
        <!-- web presence edit modal  -->
        <div class="edit__icon">
          <b-img
            v-b-modal.modal-web-presence
            class="ml-auto"
            src="./../../assets/images/logo/bold.png"
          />
        </div>
      </div>
      <!-- <b-form> -->
      <b-row>
        <b-col v-for="(web, index) in web_presences" :key="index" sm="6">
          <b-form-group :label="web.title">
            <h5 class="text-primary">
              {{ web.link }}
            </h5>
            <b-img
              class="ml-2"
              src="./../../assets/images/icons/Icon.png"
              @click="removeLang(index)"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            See More
          </b-button>
        </b-col> -->
      </b-row>
    </b-card-body>

    <!-- modal instance connect -->
    <b-modal
      id="modal-instant-connect"
      ok-only
      ok-title="Submit"
      centered
      size="lg"
      title="Connect Via"
      @ok="handleInstantConnect"
    >
      <div class="d-flex justify-content-center">
        <div>
          <b-row>
            <b-col md="8">
              <span>
                <b-img
                  src="./../../assets/images/avatars/w.png"
                  rounded
                  fluid
                  alt="whatsapp photo"
              /></span>
              <span class="ml-2">Whatsapp Number</span>
              <b-form-input
                v-model="instantConnectForm.whatsapp"
                class="ml-5"
                placeholder="Enter Whatsapp Number"
              />
            </b-col>
            <b-col class="mt-3" md="4">
              <b-form-radio
                v-model="instantConnectForm.priority"
                name="some-radios"
                value="whatsapp"
                class="ml-5"
              >
                Priorities
              </b-form-radio>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <span>
                <b-img src="./../../assets/images/avatars/we.png" rounded fluid
              /></span>
              <span class="ml-2">WeChat ID</span>
              <b-form-input
                v-model="instantConnectForm.we_chat"
                class="ml-5"
                placeholder="Enter ID here"
              />
            </b-col>

            <b-col class="mt-3" md="4">
              <b-form-radio
                v-model="instantConnectForm.priority"
                name="some-radios"
                value="we_chat"
                class="ml-5"
              >
                Priorities
              </b-form-radio>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <span>
                <b-img
                  src="./../../assets/images/avatars/viber.png"
                  rounded
                  fluid
              /></span>
              <span class="ml-2">Viber ID</span>
              <b-form-input
                v-model="instantConnectForm.viber"
                class="ml-5"
                placeholder="Enter Viber ID here"
              />
            </b-col>

            <b-col class="mt-3" md="4">
              <b-form-radio
                v-model="instantConnectForm.priority"
                name="some-radios"
                value="viber"
                class="ml-5"
              >
                Priorities
              </b-form-radio>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <span>
                <b-img
                  src="./../../assets/images/avatars/line.png"
                  rounded
                  fluid
              /></span>
              <span class="ml-2">Line ID</span>
              <b-form-input
                v-model="instantConnectForm.line"
                class="ml-5"
                placeholder="Enter ID here"
              />
            </b-col>

            <b-col class="mt-3" md="4">
              <b-form-radio
                v-model="instantConnectForm.priority"
                name="some-radios"
                value="line"
                class="ml-5"
              >
                Priorities
              </b-form-radio>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <span>
                <b-img
                  src="./../../assets/images/avatars/skype.png"
                  rounded
                  fluid
              /></span>
              <span class="ml-2">Skype ID</span>
              <b-form-input
                v-model="instantConnectForm.skype"
                class="ml-5"
                placeholder="Enter ID here"
              />
            </b-col>

            <b-col class="mt-3" md="4">
              <b-form-radio
                v-model="instantConnectForm.priority"
                name="some-radios"
                value="skype"
                class="ml-5"
              >
                Priorities
              </b-form-radio>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <span>
                <b-img
                  src="./../../assets/images/avatars/botim.png"
                  rounded
                  fluid
              /></span>
              <span class="ml-2">Botim ID</span>
              <b-form-input
                v-model="instantConnectForm.botim"
                class="ml-5"
                placeholder="Enter ID here"
              />
            </b-col>

            <b-col class="mt-3" md="4">
              <b-form-radio
                v-model="instantConnectForm.priority"
                name="some-radios"
                value="botim"
                class="ml-5"
              >
                Priorities
              </b-form-radio>
            </b-col>
          </b-row>
          <b-row class="my-auto">
            <b-col md="8">
              <span>
                <b-img
                  src="./../../assets/images/avatars/tele.png"
                  rounded
                  fluid
              /></span>
              <span class="ml-2">Telegram ID</span>
              <b-form-input
                v-model="instantConnectForm.telegram"
                class="ml-5"
                placeholder="Enter ID here"
              />
            </b-col>

            <b-col class="mt-3" md="4">
              <b-form-radio
                v-model="instantConnectForm.priority"
                name="some-radios"
                value="telegram"
                class="ml-5"
              >
                Priorities
              </b-form-radio>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <!-- modal web presence -->
    <b-modal
      id="modal-web-presence"
      centered
      title="Edit/Add Web Presence"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      size="lg"
      @ok="handleWebPresence"
    >
      <div class="pl-lg-5 pr-lg-5 ml-3 mr-3">
        <b-form-group label="Title" label-for="Title">
          <b-form-input
            id="title"
            v-model="webPresenceForm.title"
            placeholder="Enter Web Title"
          />
        </b-form-group>
        <b-form-group label="URL" label-for="URL">
          <b-form-input
            id="url"
            v-model="webPresenceForm.link"
            placeholder="Enter Web URL"
          />
        </b-form-group>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import store from "@/store"
import {
  BCard,
  BCardBody,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BImg,
  BRow,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BFormRadio,
    BRow,
    BCol,
    BImg,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      webPresenceForm: {
        title: null,
        link: null,
      },
      priority_app_number: null,
      priority_app_image: null,
      othersImage: [],
    }
  },
  computed: {
    web_presences() {
      return store.state.auth.user.candidate.web_links
    },

    instantConnectForm() {
      return {
        whatsapp: store.state.auth.user.candidate.whatsapp,
        we_chat: store.state.auth.user.candidate.we_chat,
        viber: store.state.auth.user.candidate.viber,
        line: store.state.auth.user.candidate.line,
        skype: store.state.auth.user.candidate.skype,
        botim: store.state.auth.user.candidate.botim,
        telegram: store.state.auth.user.candidate.telegram,
        priority: store.state.auth.user.candidate.priority,
      }
    },
  },
  mounted() {
    this.priority_app()
  },
  methods: {
    removeLang(indexed) {
      const newArray = this.web_presences.filter(
        (item, index) => index !== indexed
      )
      const data = {
        web_presences: newArray,
      }
      console.log(data)
      store.dispatch("candidateProfile/updateResume", data)
    },

    priority_app() {
      if (store.state.auth.user.candidate.priority === "whatsapp") {
        this.priority_app_number = store.state.auth.user.candidate.whatsapp
        this.priority_app_image = require("@/assets/images/avatars/w.png")

        this.set_others()
      }

      if (store.state.auth.user.candidate.priority === "we_chat") {
        this.priority_app_number = store.state.auth.user.candidate.we_chat
        this.priority_app_image = require("@/assets/images/avatars/we.png")

        this.set_others()
      }

      if (store.state.auth.user.candidate.priority === "viber") {
        this.priority_app_number = store.state.auth.user.candidate.viber
        this.priority_app_image = require("@/assets/images/avatars/viber.png")

        this.set_others()
      }

      if (store.state.auth.user.candidate.priority === "line") {
        this.priority_app_number = store.state.auth.user.candidate.line
        this.priority_app_image = require("@/assets/images/avatars/line.png")

        this.set_others()
      }

      if (store.state.auth.user.candidate.priority === "skype") {
        this.priority_app_number = store.state.auth.user.candidate.skype
        this.priority_app_image = require("@/assets/images/avatars/skype.png")

        this.set_others()
      }

      if (store.state.auth.user.candidate.priority === "botim") {
        this.priority_app_number = store.state.auth.user.candidate.botim
        this.priority_app_image = require("@/assets/images/avatars/botim.png")

        this.set_others()
      }

      if (store.state.auth.user.candidate.priority === "telegram") {
        this.priority_app_number = store.state.auth.user.candidate.telegram
        this.priority_app_image = require("@/assets/images/avatars/tele.png")

        this.set_others()
      }
    },
    set_others() {
      if (
        store.state.auth.user.candidate.we_chat &&
        store.state.auth.user.candidate.priority !== "we_chat"
      ) {
        this.othersImage.push(require("@/assets/images/avatars/we.png"))
      }
      if (
        store.state.auth.user.candidate.viber &&
        store.state.auth.user.candidate.priority !== "viber"
      ) {
        this.othersImage.push(require("@/assets/images/avatars/viber.png"))
      }
      if (
        store.state.auth.user.candidate.line &&
        store.state.auth.user.candidate.priority !== "line"
      ) {
        this.othersImage.push(require("@/assets/images/avatars/line.png"))
      }
      if (
        store.state.auth.user.candidate.skype &&
        store.state.auth.user.candidate.priority !== "skype"
      ) {
        this.othersImage.push(require("@/assets/images/avatars/skype.png"))
      }
      if (
        store.state.auth.user.candidate.botim &&
        store.state.auth.user.candidate.priority !== "botim"
      ) {
        this.othersImage.push(require("@/assets/images/avatars/botim.png"))
      }
      if (
        store.state.auth.user.candidate.tele &&
        store.state.auth.user.candidate.priority !== "tele"
      ) {
        this.othersImage.push(require("@/assets/images/avatars/tele.png"))
      }
    },
    handleInstantConnect(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitInstantConnect()
    },
    submitInstantConnect() {
      this.othersImage = []
      store.dispatch("candidateProfile/updateResume", this.instantConnectForm)
      this.$nextTick(() => {
        this.$bvModal.hide("modal-instant-connect")
      })
      setTimeout(() => {
        this.priority_app()
      }, 500)
    },
    handleWebPresence(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitWebPresence()
    },
    submitWebPresence() {
      store.dispatch("candidateProfile/updateResume", this.webPresenceForm)
      this.$nextTick(() => {
        this.$bvModal.hide("modal-web-presence")
      })
    },
  },
}
</script>
<style scoped>
.text-large {
  font-size: 120%;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.edit__icon :hover {
  cursor: pointer;
}
.web_presence {
  margin: 0px;
}
</style>
