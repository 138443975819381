<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center justify-content-between mb-1">
        <b-card-title class="ml-25 my-auto">
          <h3 class="bold">Achievement and Awards</h3>
        </b-card-title>
        <b-button
          v-b-modal.modal-achievement-award
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
        >
          <!-- @click="getCertificationOnState(certification)" -->
          Add New Achievements
        </b-button>
      </div>
      <div
        v-for="(item, index) in awards"
        :key="index"
        class="thin-border mb-2"
      >
        <b-card-body>
          <div class="d-flex justify-content-between card_title mt-0">
            <h6 class="mt-0 font-weight-bold">
              {{ item.award }}
            </h6>
            <div class="edit__icon">
              <!-- <img class="edit__icon" src="./../../assets/images/logo/bold.png" alt="" /> -->
              <b-img
                v-b-modal.modal-achievement-award
                class="ml-auto mr-2"
                src="./../../assets/images/logo/bold.png"
                @click="getAwardOnState(item)"
              />
              <feather-icon
                size="30"
                icon="TrashIcon"
                @click="deleteAchievement(item)"
              />
            </div>
          </div>
          <div class="pb-1 mt-0">
            <span>issued by</span>
            <span class="text-sm institute_name">{{ item.issued_by }}</span>
          </div>
          <div class="row">
            <div class="desiredCtc col-lg-2 col-sm-2">
              <div class="mb-2">
                <span class="mb-1">Issued On</span>
                <h6>{{ item.issued_date }}</h6>
              </div>
            </div>
          </div>

          <!-- <div class="row">
            <div class="desiredCtc col-lg-2 col-sm-2">
              <div class="mb-2">
                <span class="mb-1">Credential Id</span>
                <h6>VeP#$123hj</h6>
              </div>
            </div>
            <div class="col-lg-2 col-sm-2">
              <div class="mb-2">
                <span class="mb-1">Credential URL</span>
                <h6>www.vep.com</h6>
              </div>
            </div>
          </div> -->
          <p>
            {{ item.description }}
          </p>
        </b-card-body>
      </div>
      <!-- </b-card-header> -->
    </b-card>
    <!-- modal vertical center -->
    <b-modal
      id="modal-achievement-award"
      centered
      title="Achievements and Awards"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      size="lg"
      @ok="handleAwardForm"
    >
      <div class="form">
        <b-form-group
          label="Award/Achievement Title"
          label-for="Award/Achievement Title"
        >
          <b-form-input id="award_achievement" v-model="awardForm.award" />
        </b-form-group>
        <b-form-group label="Issued By" label-for="Issued By">
          <b-form-input id="issued_by" v-model="awardForm.issued_by" />
        </b-form-group>
        <b-form-group label="Issue Date" label-for="Issue Date">
          <b-form-datepicker
            id="datepicker-placeholder"
            v-model="awardForm.issued_date"
            placeholder="DD/MM/YYY"
            local="en"
            initial-date="2020-06-16"
            class="w-50"
          />
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            id="textarea-rows"
            v-model="awardForm.description"
            placeholder="Type Here..."
            rows="4"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import {
  BAvatar,
  BAvatarGroup,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BFormTextarea,
    BCardTitle,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BFormGroup,
    BFormInput,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      awardForm: {
        key: null,
        award: null,
        issued_by: null,
        issued_date: null,
        description: null,
      },
      avatars: [
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          name: "Billy Hopkins",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          name: "Amy Carson",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
          name: "Brandon Miles",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
          name: "Daisy Weber",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
          name: "Jenny Looper",
        },
      ],
    }
  },
  computed: {
    user() {
      return store.state.auth.user
    },
    awards() {
      return store.state.auth.user.candidate.award
    },
  },
  methods: {
    handleAwardForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitAwardForm()
    },
    deleteAchievement(data) {
      const formData = {
        id: this.user.id,
        key: data.key,
      }
      store.dispatch("candidateProfile/deleteAchievement", formData)
    },
    submitAwardForm() {
      store.dispatch("candidateProfile/updateResume", this.awardForm)
      this.$nextTick(() => {
        this.$bvModal.hide("modal-achievement-award")
      })
      this.clearState()
    },
    getAwardOnState(item) {
      this.awardForm.key = item.key
      this.awardForm.award = item.award
      this.awardForm.issued_by = item.issued_by
      this.awardForm.issued_date = item.issued_date
      this.awardForm.description = item.description
    },
    clearState() {
      this.awardForm.key = null
      this.awardForm.award = null
      this.awardForm.issued_by = null
      this.awardForm.issued_date = null
      this.awardForm.description = null
    },
  },
}
</script>

<style scoped>
.text-large {
  font-size: 120%;
}
.bold {
  font-weight: bold;
}
.thin-border {
  border: 0.5px solid lightgray;
  border-radius: 6px;
}
.edit__icon :hover {
  cursor: pointer;
}
.card_title {
  margin-top: -18px;
}
.institute_name {
  font-weight: 700;
  margin-left: 3px;
}
.form {
  margin: 10px 120px;
}
</style>
