<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <basic-info />
        <brief />
      </div>
      <div class="col-lg-6">
        <instant-connect />

        <contact-details />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <experience @custom-event-refresh-experience="reloadExperience" />
        <qualification />
        <certification />
        <achivement-award />
      </div>
      <div class="col-lg-4">
        <industry ref="refIndustry" />
        <function ref="refFunction" />
        <sub-function ref="subFunction" />
        <skills />
        <language />
        <executive-network />
        <expert-network />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store"
import AchivementAward from "./AchivementAward.vue"
import BasicInfo from "./BasicInfo.vue"
import Brief from "./Brief.vue"
import Certification from "./Certification.vue"
import ContactDetails from "./ContactDetails.vue"
import ExecutiveNetwork from "./ExecutiveNetwork.vue"
import Experience from "./Experience.vue"
import ExpertNetwork from "./ExpertNetwork.vue"
import Function from "./Function.vue"
import Industry from "./Industry.vue"
import InstantConnect from "./InstantConnect.vue"
import Language from "./Language.vue"
import Qualification from "./Qualification.vue"
import Skills from "./Skills.vue"
import SubFunction from "./SubFunction.vue"

export default {
  components: {
    BasicInfo,
    ExecutiveNetwork,
    ContactDetails,
    InstantConnect,
    ExpertNetwork,
    Brief,
    Qualification,
    Certification,
    AchivementAward,
    Industry,
    Function,
    SubFunction,
    Skills,
    Language,
    Experience,
  },

  created() {
    store.dispatch("candidateProfile/getSkills")
  },
  methods: {
    reloadExperience() {
      setTimeout(() => {
        this.$refs.refIndustry.getExperience()
        this.$refs.refFunction.getExperience()
        this.$refs.subFunction.getExperience()
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped></style>
