<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center justify-content-between mb-1">
        <b-card-title class="ml-25 my-auto">
          <h3 class="bold">Certification</h3>
        </b-card-title>
        <b-button
          v-b-modal.modal-certificate
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="getCertificationOnStateNew()"
        >
          Add New Certification
        </b-button>
      </div>
      <div
        v-for="(certification, index) in certifications"
        :key="index"
        class="thin-border mb-2"
      >
        <b-card-body>
          <div class="d-flex justify-content-between card_title mt-0">
            <h6 class="mt-0 font-weight-bold">
              {{ certification.certification_name }}
            </h6>

            <div class="edit__icon">
              <b-img
                v-b-modal.modal-certificate
                class="ml-auto mr-2"
                src="./../../assets/images/logo/bold.png"
                @click="getCertificationOnState(certification)"
              />
              <feather-icon
                size="30"
                icon="TrashIcon"
                @click="deleteCertifications(certification)"
              />
            </div>
          </div>
          <div class="pb-1 mt-0">
            <p>
              {{ certification.description }}
            </p>
            <span>issued by</span>
            <span class="text-sm institute_name">{{
              certification.institution_name
            }}</span>
          </div>
          <div class="row">
            <div class="desiredCtc col-lg-2 col-sm-2">
              <div class="mb-2">
                <span class="mb-1">Issued On</span>
                <h6>{{ certification.issue_date }}</h6>
              </div>
            </div>
            <div
              class="col-lg-4 col-sm-4"
              v-if="certification.expired === 'true'"
            >
              <div class="mb-2">
                <span class="mb-1">Expiry On</span>
                <h6>{{ certification.actual_end_date }}</h6>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="desiredCtc col-lg-2 col-sm-2">
              <div class="mb-2">
                <span class="mb-1">Credential Id</span>
                <h6>{{ certification.credential_id }}</h6>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="mb-2">
                <span class="mb-1">Credential URL</span>
                <h6>{{ certification.credential_url }}</h6>
              </div>
            </div>
          </div>
        </b-card-body>
      </div>
    </b-card>
    <!-- modal vertical center -->
    <b-modal
      id="modal-certificate"
      centered
      title="Add/Edit Certification"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      size="lg"
      @ok="handleCertificationForm"
    >
      <div class="form">
        <b-form-group label="Course Title" label-for="Course Title">
          <b-form-input
            id="course_title"
            v-model="certificationForm.certification_name"
          />
        </b-form-group>
        <b-form-group
          label="Name of the Institute"
          label-for="Name of the Institute"
        >
          <b-form-input
            id="institute_name"
            v-model="certificationForm.institution_name"
          />
        </b-form-group>
        <b-form-group
          label="Does the Certificate Expired?"
          label-for="Does the Certificate Expired?"
        >
          <div class="d-flex">
            <b-form-radio
              v-model="certificationForm.expired"
              name="some-radios"
              value="true"
              class="mr-5"
            >
              Yes
            </b-form-radio>
            <b-form-radio
              v-model="certificationForm.expired"
              name="some-radios"
              value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-form-group>
        <div class="d-flex align-items-center">
          <b-form-group label="Issue Date" label-for="Issue Date" class="mr-2">
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="certificationForm.issue_date"
              placeholder="DD/MM/YYY"
              local="en"
              initial-date="2020-06-16"
            />
          </b-form-group>
          <b-form-group
            label="End Date"
            label-for="End Date"
            v-if="certificationForm.expired === 'true'"
          >
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="certificationForm.end_date"
              placeholder="DD/MM/YYY"
              local="en"
              initial-date="2020-06-16"
            />
          </b-form-group>
        </div>
        <b-form-group label="Credential ID" label-for="Credential ID">
          <b-form-input
            id="credential_id"
            v-model="certificationForm.credential_id"
          />
        </b-form-group>
        <b-form-group label="Credential URL" label-for="Credential URL">
          <b-form-input
            id="credential_url"
            v-model="certificationForm.credential_url"
          />
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            id="textarea-rows"
            v-model="certificationForm.description"
            placeholder="Type Here..."
            rows="4"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import {
  BAvatar,
  BAvatarGroup,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BFormTextarea,
    BFormDatepicker,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BImg,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      certificationForm: {
        certification_name: null,
        institution_name: null,
        expired: "false",
        issue_date: null,
        end_date: null,
        credential_id: null,
        credential_url: null,
        description: null,
        key: null,
      },
    }
  },
  computed: {
    user() {
      return store.state.auth.user
    },
    certifications() {
      return store.state.auth.user.candidate.certifications
    },
  },
  methods: {
    handleCertificationForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitCertificationForm()
    },
    submitCertificationForm() {
      store.dispatch("candidateProfile/updateResume", this.certificationForm)
      this.$nextTick(() => {
        this.$bvModal.hide("modal-certificate")
      })
      this.clearState()
    },

    deleteCertifications(data) {
      const formData = {
        id: this.user.id,
        key: data.key,
      }
      store.dispatch("candidateProfile/deleteCertifications", formData)
    },
    getCertificationOnStateNew() {
      this.certificationForm.key = null
      this.certificationForm.certification_name = null
      this.certificationForm.institution_name = null
      this.certificationForm.expired = "false"
      this.certificationForm.issue_date = null
      this.certificationForm.end_date = null
      this.certificationForm.credential_id = null
      this.certificationForm.credential_url = null
      this.certificationForm.description = null
    },
    getCertificationOnState(certification) {
      this.certificationForm.key = certification.key
      this.certificationForm.certification_name =
        certification.certification_name
      this.certificationForm.institution_name = certification.institution_name
      this.certificationForm.expired = certification.expired
      this.certificationForm.issue_date = certification.actual_issue_date
      this.certificationForm.end_date = certification.actual_end_date
      this.certificationForm.credential_id = certification.credential_id
      this.certificationForm.credential_url = certification.credential_url
      this.certificationForm.description = certification.description
    },
    clearState() {
      this.certificationForm.key = null
      this.certificationForm.certification_name = null
      this.certificationForm.institution_name = null
      this.certificationForm.expired = null
      this.certificationForm.issue_date = null
      this.certificationForm.end_date = null
      this.certificationForm.credential_id = null
      this.certificationForm.credential_url = null
      this.certificationForm.description = null
    },
  },
}
</script>

<style scoped>
.text-large {
  font-size: 120%;
}
.bold {
  font-weight: bold;
}
.thin-border {
  border: 0.5px solid lightgray;
  border-radius: 6px;
}
.edit__icon :hover {
  cursor: pointer;
}
.card_title {
  margin-top: -18px;
}
.institute_name {
  font-weight: 700;
  margin-left: 3px;
}

@media only screen and (min-width: 500px) {
  .form {
    margin: 0px 100px;
  }
}
</style>
