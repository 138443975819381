<template>
  <div>
    <b-card class="">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <b-card-title class="ml-25 my-auto">
          <h3 class="bold">Qualification</h3>
        </b-card-title>
        <b-button
          v-b-modal.modal-qualification
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="getEducationOnStateNew()"
        >
          Add New Qualification
        </b-button>
      </div>
      <b-card
        v-for="education in educations"
        :key="education.id"
        class="thin-border"
      >
        <div class="company__title">
          <h6>{{ education.institute_name }}</h6>
          <div class="edit__icon">
            <b-img
              v-b-modal.modal-qualification
              class="ml-auto mr-2"
              src="./../../assets/images/logo/bold.png"
              @click="getEducationOnState(education)"
            />
            <feather-icon
              size="30"
              icon="TrashIcon"
              @click="deleteExperience(education)"
            />
          </div>
        </div>

        <!-- timeline -->
        <b-card-body>
          <app-timeline>
            <app-timeline-item variant="primary">
              <div
                class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
              >
                <h6>{{ education.degree }}, {{ education.field_of_study }}</h6>
              </div>
              <p>
                {{ education.from }} -
                {{ education.to ? education.to : "Present" }} -
                {{ getEducationDate(education.from, education.to) }}
              </p>
              <b-media no-body>
                <p>
                  {{ education.description }}
                </p>
              </b-media>
            </app-timeline-item>
            <!--/ timeline -->
          </app-timeline>
        </b-card-body>
      </b-card>
    </b-card>

    <!-- modal vertical center -->
    <b-modal
      id="modal-qualification"
      centered
      title="Add/Edit Qualification"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      size="lg"
      @ok="handleEducationForm"
    >
      <div class="pl-lg-5 pr-lg-5 ml-lg-3 mr-lg-3">
        <b-form-group label="Degree" label-for="degree">
          <b-form-input
            id="degree"
            v-model="qualificationForm.degree"
            placeholder="Enter Degree"
          />
        </b-form-group>
        <b-form-group label="Field of Study" label-for="fieldOfStudy">
          <b-form-input
            id="fieldOfStudy"
            v-model="qualificationForm.field_of_study"
            placeholder="Enter Field of Study"
          />
        </b-form-group>
        <b-form-group label="Name of Institution" label-for="institution">
          <b-form-input
            id="institution"
            v-model="qualificationForm.institute_name"
            placeholder="Enter Name of Institution"
          />
        </b-form-group>
        <b-form-group label="Is the course completed?" label-for="isCompleted">
          <b-form-radio-group
            id="isCompleted"
            v-model="qualificationForm.completed"
            :options="isCompletedOptions"
            name="isCompleted"
          />
        </b-form-group>
        <div class="row">
          <div class="col-lg-6">
            <label for="startDate">Start Date</label>
            <b-form-datepicker
              id="startDate"
              v-model="qualificationForm.from"
              placeholder="From"
              class="mb-1"
            />
          </div>
          <div class="col-lg-6" v-if="qualificationForm.completed === 'true'">
            <label for="endDate">End Date</label>
            <b-form-datepicker
              id="endDate"
              v-model="qualificationForm.to"
              placeholder="To"
              class="mb-1"
            />
          </div>
        </div>
        <b-form-group label="Description" label-for="desc">
          <b-form-textarea
            id="desc"
            v-model="qualificationForm.description"
            placeholder="Textarea"
            rows="5"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import {
  BAvatar,
  BAvatarGroup,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BFormValidFeedback,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue"
import moment from "moment"
import Ripple from "vue-ripple-directive"
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BButton,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormSelect,
    BFormTextarea,
    BFormValidFeedback,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      qualificationForm: {
        degree: null,
        field_of_study: null,
        institute_name: null,
        completed: "false",
        from: null,
        to: null,
        id: null,
        description: null,
      },
      isCompletedOptions: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" },
      ],
    }
  },
  computed: {
    user() {
      return store.state.auth.user
    },
    educations() {
      return store.state.auth.user.candidate?.educations
    },
  },
  methods: {
    getUserDate(from) {
      const now = moment(new Date(), "MM-DD-YYYY")
      const then = moment(from)

      return moment.duration(then.diff(now)).humanize(true)
    },
    getEducationDate(from, to) {
      if (to) {
        return moment.duration(moment(from).diff(moment(to))).humanize(true)
      }

      const present = new Date()
      return moment.duration(moment(from).diff(moment(present))).humanize(true)
    },
    handleEducationForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitEducationForm()
    },
    submitEducationForm() {
      store.dispatch(
        "candidateProfile/profileQualificationDetails",
        this.qualificationForm
      )
      this.$nextTick(() => {
        this.$bvModal.hide("modal-qualification")
      })
      this.clearState()
    },
    getEducationOnStateNew() {
      this.qualificationForm.degree = ""
      this.qualificationForm.field_of_study = ""
      this.qualificationForm.institute_name = ""
      this.qualificationForm.completed = "false"
      this.qualificationForm.from = ""
      this.qualificationForm.to = ""
      this.qualificationForm.description = ""
    },
    getEducationOnState(education) {
      console.log(education)

      this.qualificationForm.id = education.id
      this.qualificationForm.degree = education.degree
      this.qualificationForm.field_of_study = education.field_of_study
      this.qualificationForm.institute_name = education.institute_name
      this.qualificationForm.completed = education.completed
      this.qualificationForm.from = education.from
      this.qualificationForm.to = education.to
      this.qualificationForm.description = education.description
    },
    clearState() {
      this.qualificationForm.degree = null
      this.qualificationForm.field_of_study = null
      this.qualificationForm.institute_name = null
      this.qualificationForm.completed = "false"
      this.qualificationForm.from = null
      this.qualificationForm.to = null
      this.qualificationForm.description = null
      this.qualificationForm.id = null
    },

    deleteExperience(data) {
      const formData = {
        id: data.id,
      }
      store.dispatch(
        "candidateProfile/profileQualificationDetailsDelete",
        formData
      )
    },
  },
}
</script>
<style>
.bold {
  font-weight: bold;
}
.thin-border {
  border: 0.5px solid lightgray;
  border-radius: 6px;
}
</style>
