<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h3 class="bold">Function</h3>
      <!-- <div class="edit__icon">
        <b-img
          v-if="user_functions"
          v-b-modal.modal-function
          src="./../../assets/images/logo/bold.png"
        />
        <b-button
          v-if="!user_functions"
          v-b-modal.modal-function
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
        >
          Add Function
        </b-button>
      </div> -->
    </b-card-header>
    <b-card-body v-if="view_funcs">
      <b-button
        v-for="(func, index) in view_funcs"
        :key="index"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-2 mr-2"
      >
        {{ func }}
      </b-button>
    </b-card-body>
    <!-- modal vertical center -->
    <b-modal
      id="modal-function"
      centered
      title="Edit/Add Function"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok="handleFunctionForm"
    >
      <h6>Function</h6>
      <v-select
        v-model="functionForm.functions"
        multiple
        label="title"
        :options="functions"
        :get-option-label="getSelected"
      />
    </b-modal>
  </b-card>
</template>

<script>
import store from "@/store"
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BFormCheckbox,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BNavbar,
  BNavbarToggle,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BCard,
    BButton,
    BImg,
    BNavbar,
    BNavbarToggle,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      functionForm: {
        functions: JSON.parse(store.state.auth.user.candidate.functions),
      },
      functions: store.state.utilities.functions,
      view_funcs: [],
    }
  },
  computed: {
    user_functions() {
      return JSON.parse(store.state.auth.user.candidate.functions)
    },
  },
  created() {
    // store.dispatch("utilities/candidate_functions")
  },
  mounted() {
    this.getExperience()
  },
  methods: {
    getExperience() {
      const funcData = store.state.auth.user.candidate.experiences
      const funcArray = []
      funcData.forEach((element) => {
        funcArray.push(...JSON.parse(element.function))
      })
      this.view_funcs = Array.from(new Set(funcArray.map((item) => item.name)))
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
    handleFunctionForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitFunctionForm()
    },
    submitFunctionForm() {
      store.dispatch("candidateProfile/updateResume", this.functionForm)
    },
  },
}
</script>
<style lang="scss" scoped>
.progress__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
Footer
