<template>
  <div>
    <!-- if the information present -->
    <b-card
      v-if="user.candidate.charge_per_hour"
      no-body
      class="fixed-height pb-1"
    >
      <b-card-header
        class="d-flex justify-content-between align-items-center card_title"
      >
        <h3 class="bold">Expert Network</h3>
        <div class="edit__icon">
          <!-- <img class="edit__icon" src="./../../assets/images/logo/bold.png" alt="" /> -->
          <b-img
            v-b-modal.modal-edit-expert
            class="ml-auto"
            src="./../../assets/images/logo/bold.png"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <div class="row pl-2">
          <div class="desiredCtc col-lg-4 col-md-3 col-sm-6 v-center">
            <div class="mb-2">
              <span class="mb-1">Desired charges per hour</span>
              <h4 class="text-primary">
                ${{ user.candidate.charge_per_hour || "Not Given" }}
              </h4>
            </div>
          </div>
          <div class="desiredCtc col-lg-5 col-md-8 col-sm-8">
            <div class="row">
              <div class="desiredCtc col-sm-12 pb-1">
                <b>Expertise</b>
              </div>
            </div>
            <b-button
              v-for="expertize in multipleExpertise"
              :key="expertize.id"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-1 mb-1"
              variant="outline-primary"
            >
              {{ expertize.name }}
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- if the information is not present  -->
    <b-card v-if="!user.candidate.charge_per_hour" class="bg text-white">
      <b-card-body>
        <div>
          <h3 class="m-0 text-white">Expert Network</h3>
          <h4 class="text-white mt-2">
            Would you like to participate in paid consultation with VEP’s
            clients ?
          </h4>
        </div>
        <b-button v-b-modal.modal-edit-expert class="mt-1" variant="dark">
          Get Started
        </b-button>
      </b-card-body>
    </b-card>

    <!-- modal vertical center -->
    <b-modal
      id="modal-edit-expert"
      centered
      title="Edit Expert Details"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok="handleExpertForm"
    >
      <div class="form">
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2">
            <!-- currency -->
            <b-form-group label="Currenncy" label-for="currency">
              <validation-provider
                #default="{ errors }"
                name="Currency"
                rules="required"
              >
                <b-form-select
                  v-model="expertForm.currency"
                  label="name"
                  :options="currencyOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- desired-charge -->
            <b-form-group
              label="Desired Charge per Hour"
              label-for="desired-salary"
            >
              <validation-provider
                #default="{ errors }"
                name="Desired Annual Salary"
                rules="required"
              >
                <b-form-input
                  id="desired-salary"
                  v-model="expertForm.desired"
                  type="number"
                  name="desired-salary"
                  :state="errors.length > 0 ? false : null"
                  placeholder="50000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Choose Your Expertise" label-for="expertise">
              <validation-provider
                #default="{ errors }"
                name="Preferred Location"
                rules="required"
              >
                <v-select
                  v-model="expertForm.expertise"
                  multiple
                  label="title"
                  :options="expertise"
                  :get-option-label="getSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store"
import { required } from "@validations"
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BNavbar,
  BNavbarToggle,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"

export default {
  components: {
    BCard,
    BButton,
    BLink,
    BButton,
    BForm,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCard,
    BButton,
    BImg,
    BNavbar,
    vSelect,
    BNavbarToggle,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      expertForm: {
        currency: null,
        desired: null,
        expertise: null,
      },
      expertise: store.state.utilities.expertise
        ? store.state.utilities.expertise
        : null,

      currencyOptions: [
        { value: "usd", text: "US Doller(USD)" },
        { value: "inr", text: "Indian Rupee(INR)" },
        { value: "cny", text: "China Yuan(CNY)" },
      ],

      currentSalary: "",
      desiredSalary: "",
      relocate: false,
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
    }
  },
  computed: {
    user() {
      return store.state.auth.user
    },
    multipleExpertise() {
      return JSON.parse(store.state.auth.user.candidate.expertise)
    },
  },
  created() {
    store.dispatch("utilities/expertise")
    // if (!store.state.utilities.countries) store.dispatch('utilities/countries')
  },
  methods: {
    handleExpertForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitExpertForm()
    },
    submitExpertForm() {
      store.dispatch("candidateProfile/updateResume", this.expertForm)
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
  },
}
</script>
<style lang="scss" scoped>
.fixed-height {
  //  height: 260px;
}
.progress__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.v-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bold {
  font-weight: bold;
}
.thin-border {
  border: 0.5px solid lightgray;
  border-radius: 6px;
}
.company__title {
  display: flex;
  justify-content: space-between;
}
.edit__icon :hover {
  cursor: pointer;
}
.bg {
  background-color: #869a8c;
}
</style>
