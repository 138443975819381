<template>
  <b-card no-body class="card-apply-job">
    <b-card-header>
      <div class="header__container">
        <h3 class="bold my-auto">Contact Details</h3>
        <div class="d-flex align-items-center">
          <!-- <b-button
            v-b-modal.modal-contact
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Add New Contact
          </b-button> -->
          <!-- <img class="edit__icon" src="./../../assets/images/logo/bold.png" alt="" /> -->
          <div class="edit_icon">
            <b-img
              v-b-modal.modal-contact
              class="ml-1 cursor:pointer"
              src="./../../assets/images/logo/bold.png"
            />
          </div>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="row">
        <div class="desiredCtc col-lg-6 col-sm-6">
          <div class="mb-2" v-if="user_data.phone">
            <b-button
              id="popover-button-dark"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="outline-dark"
            >
              <feather-icon size="16" icon="PhoneIcon" />{{ user_data.phone }}
            </b-button>
          </div>
          <div class="mb-2" v-if="user_data.email">
            <b-button
              id="popover-button-dark"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="outline-dark"
            >
              <feather-icon size="16" icon="MailIcon" /> {{ user_data.email }}
            </b-button>
          </div>
        </div>
        <div class="desiredCtc col-lg-6 col-sm-6">
          <div class="mb-2">
            <span class="mb-1">Citizenship:</span>
            <h5 class="">{{ user_data.country }}</h5>
          </div>
          <div class="mb-2">
            <span class="mb-1">Address:</span>
            <h5 class="">
              {{ user_data.address }}<br />
              {{ user_data.address2 }} <br />
              {{ user_data.state }} <br />
              {{ user_data.zip_code }} <br />
              {{ user_data.country }}
            </h5>
          </div>
        </div>
      </div>
    </b-card-body>
    <!-- modal vertical center -->
    <b-modal
      id="modal-contact"
      centered
      title="Edit Contact details"
      cancel-title="Close"
      ok-only
      size="lg"
      ok-title="Save Details"
      @ok="handleLanguagesForm"
    >
      <b-row class="d-lg-flex justify-content-between mx-1">
        <b-col sm="8" md="6" lg="12" class="pt-2 pr-2 mt-0 mr-1 left_form">
          <!-- <b-form-group
              label="Country Code"
              label-for="Country Code"
              class="w-50"
            >
              <b-form-select
                v-model="selectPrimCountryCode"
                :options="primCountryCodeOptions"
              />
            </b-form-group> -->
          <b-form-group
            label="Primary Mobile Number"
            label-for="Primary Mobile Number"
          >
            <b-form-input
              id="primary_number"
              v-model="formData.phone_number"
              placeholder="Enter Mobile Number"
            />
          </b-form-group>
          <!-- <div class="d-flex">
            <b-form-group
              label="Country Code"
              label-for="Country Code"
              class="w-50"
            >
              <b-form-select
                v-model="selectSecCountryCode"
                :options="secCountryCodeOptions"
              />
            </b-form-group>

            <b-form-group
              label="Secondary Mobile Number"
              label-for="Secondary Mobile Number"
              class="w-75 ml-2"
            >
              <b-form-input
                id="secondary_number"
                placeholder="Enter Mobile Number"
              />
            </b-form-group>
          </div> -->
          <b-form-group label="Primary Mail ID" label-for="Primary Mail ID">
            <b-form-input
              v-model="formData.email"
              id="primary_mail"
              placeholder="Enter Primary Mail ID"
            />
          </b-form-group>
          <!-- <b-form-group label="Work Mail ID" label-for="Work Mail ID">
            <b-form-input id="work_mail" placeholder="Enter Work Mail ID" />
          </b-form-group> -->
          <!-- <h4 class="my-1">Present Address</h4> -->
          <b-form-group label="Address Line" label-for="Address Line">
            <b-form-input
              id="address_line"
              placeholder="Enter AddressLIne"
              v-model="formData.address"
            />
          </b-form-group>
          <!-- <b-form-group label="Address Line 2" label-for="Address Line">
            <b-form-input
              v-model="formData.address2"
              id="address_line"
              placeholder="Enter AddressLIne"
            />
          </b-form-group> -->
          <div class="d-flex">
            <b-form-group label="Country" label-for="Country" class="w-50 mr-2">
              <v-select
                v-model="formData.country"
                label="name"
                :options="countryOptions"
                :get-option-label="getSelected"
              />
            </b-form-group>
            <b-form-group label="State" label-for="State" class="w-50">
              <b-form-input
                v-model="formData.state"
                id="state"
                placeholder="Enter State"
              />
            </b-form-group>
          </div>
          <div class="d-flex">
            <b-form-group label="City" label-for="City" class="w-50 mr-2">
              <b-form-input
                v-model="formData.city"
                id="city"
                placeholder="Enter City"
              />
            </b-form-group>
            <b-form-group label="Pin Code " label-for="Pin Code" class="w-50">
              <b-form-input
                v-model="formData.zip_code"
                id="pin_code"
                placeholder="Enter Pin Code"
              />
            </b-form-group>
          </div>
        </b-col>
        <!-- <b-col sm="8" md="6" lg="8" class="pt-2 pr-2 mb-0">
          <b-form-group
            label="Country With Citizenship"
            label-for="Country With Citizenship"
          >
            <v-select
              id="citizenship"
              v-model="citizenship"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="citizenshipOptions"
            />
          </b-form-group>

          <b-form-group
            label="Country With Work Permit"
            label-for="Country With Work Permit"
            class="mt-2 w-full"
          >
            <v-select
              id="work_permit"
              v-model="workPermit"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="workPermitOptions"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import store from "@/store"
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BModal,
  BRow,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"

export default {
  components: {
    BCard,
    BCol,
    BRow,
    vSelect,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      value: 25,
      selectPrimCountryCode: [],
      selectSecCountryCode: [],
      selectCountry: [],
      selectState: [],
      citizenship: [],
      workPermit: [],
      primCountryCodeOptions: [
        { value: "+61", text: "Australia(+61)" },
        { value: "+689", text: "French(+689)" },
      ],
      secCountryCodeOptions: [
        { value: "+61", text: "Australia(+61)" },
        { value: "+689", text: "French(+689)" },
      ],
      stateOptions: [
        { value: "Victoria", text: "Victoria" },
        { value: "Tasmania", text: "Tasmania" },
      ],
      citizenshipOptions: [{ title: "Option 1" }, { title: "Option 2" }],
      workPermitOptions: [{ title: "Option 1" }, { title: "Option 2" }],
      formData: {
        phone_number: store.state.auth.user.phone,
        email: store.state.auth.user.email,
        address: store.state.auth.user.address,
        country: store.state.auth.user.country,
        address2: store.state.auth.user.address2,
        state: store.state.auth.user.state,
        city: store.state.auth.user.zip_code,
        zip_code: store.state.auth.user.zip_code,
      },
    }
  },
  computed: {
    user_data() {
      return store.state.auth.user
    },
    countryOptions() {
      return store.state.utilities.countries
    },
  },
  created() {},
  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    handleLanguagesForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitLanguagesForm()
    },
    submitLanguagesForm() {
      const formData = {
        address: this.formData.address,
        city: this.formData.city,
        country: this.formData.country.name,
        email: this.formData.email,
        phone_number: this.formData.phone_number,
        state: this.formData.state,
        zip_code: this.formData.zip_code,
      }
      store.dispatch("candidateProfile/updateResume", formData)

      // this.clearState()
    },
  },
}
</script>
<style lang="scss" scoped>
.header__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.edit_icon {
  cursor: pointer;
}
@media (min-width: 990px) {
  #citizenship {
    width: 250px;
  }
  .left_form {
    border-right: 1px solid rgb(243, 243, 243);
  }
}
</style>
