<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h3 class="bold">Brief</h3>
      <div class="edit__icon">
        <!-- <img class="edit__icon" src="./../../assets/images/logo/bold.png" alt="" /> -->
        <b-img
          v-if="user.candidate.birthday"
          v-b-modal.modal-brief
          class="ml-auto"
          src="./../../assets/images/logo/bold.png"
        />
        <b-button
          v-if="!user.candidate.birthday"
          v-b-modal.modal-brief
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="mb-2 mr-2"
        >
          Add Info
        </b-button>
      </div>
    </b-card-header>
    <b-card-body v-if="user.candidate.birthday">
      <div class="">
        <div class="row">
          <div class="desiredCtc col-lg-4 col-sm-4">
            <div class="mb-2">
              <span class="mb-1">Date of Birth</span>
              <h6>{{ user.candidate.birthday }}</h6>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="mb-2">
              <span class="mb-1">Gender</span>
              <h6>{{ replacedGender }}</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <div class="mb-2">
              <p class="pb-1">About</p>
              <div class="row">
                <p class="col-lg-8">
                  {{ user.bio }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
    <b-modal
      id="modal-brief"
      centered
      title="Edit Brief Profile Details"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      size="lg"
      @ok="handleUserForm"
    >
      <div class="pl-lg-5 pr-lg-5 ml-lg-3 mr-lg-3">
        <div>
          <label for="startDate">Date of Birth</label>
          <b-form-datepicker
            id="startDate"
            v-model="userForm.dob"
            placeholder="Date of Birth"
            class="mb-1"
          />
        </div>
        <b-form-group label="Select Your Gender" label-for="gender">
          <b-form-radio-group
            id="gender"
            v-model="userForm.gender"
            :options="genderOptions"
            class=""
            name="gender"
          />
        </b-form-group>
        <b-form-group label="Your Bio" label-for="bio">
          <b-form-textarea
            id="bio"
            v-model="userForm.bio"
            placeholder="Textarea"
            rows="5"
          />
        </b-form-group>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import store from "@/store"
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BNavbar,
  BNavbarToggle,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BFormRadioGroup,
    BFormGroup,
    BFormDatepicker,
    BFormTextarea,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BCard,
    BButton,
    BImg,
    BNavbar,
    BNavbarToggle,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userForm: {
        gender: store.state.auth.user.candidate.gender,
        bio: store.state.auth.user.bio,
        dob: store.state.auth.user.candidate.birthday,
      },

      genderOptions: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
        { text: "Others", value: "others" },
      ],
    }
  },
  computed: {
    user() {
      return store.state.auth.user
    },
    replacedGender() {
      return this.user.candidate.gender.replace(
        /^./,
        this.user.candidate.gender[0].toUpperCase()
      )
    },
  },
  methods: {
    handleUserForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitUserForm()
    },
    submitUserForm() {
      store.dispatch("candidateProfile/updateResume", this.userForm)
    },
  },
}
</script>
