<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h3 class="bold">Skill</h3>
      <div class="edit__icon">
        <b-img
          v-if="skillForm.skills"
          v-b-modal.modal-skill-take
          src="./../../assets/images/logo/bold.png"
        />
        <b-button
          v-if="!skillForm.skills"
          v-b-modal.modal-skill-take
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
        >
          Add Skills
        </b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <b-button
        v-for="(skill, index) in skillForm.skills"
        :key="index"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-2 mr-2"
        >{{ skill.name }}
      </b-button>
    </b-card-body>
    <!-- modal vertical center -->
    <b-modal
      id="modal-skill-take"
      centered
      no-close-on-backdrop
      title="Edit/Add Skill"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok="handleSkillForm"
    >
      <b-form @submit.prevent="handleLogin">
        <b-form-group label="Skill" label-for="Skill">
          <v-select
            v-model="skillForm.skills"
            multiple
            label="title"
            :options="skills"
            :get-option-label="getSelected"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import store from "@/store"
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BFormGroup,
  BImg,
  BForm,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BImg,
    vSelect,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skillForm: {
        skills: store.state.candidateProfile.user_skills,
      },

      skills: store.state.utilities.skills,
      user_skills: store.state.candidateProfile?.skills,
    }
  },
  computed: {},
  created() {
    store.dispatch("utilities/skills")
  },
  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    handleSkillForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitSkillForm()
    },
    submitSkillForm() {
      store.dispatch("candidateProfile/updateResumeSkills", this.skillForm)
      this.$nextTick(() => {
        this.$bvModal.hide("modal-skill-take")
      })
    },
  },
}
</script>
