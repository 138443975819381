<template>
  <div>
    <b-card
      v-if="
        user.candidate.desired_annual_salary &&
        user.candidate.current_annual_salary
      "
      no-body
      class="card-apply-job"
    >
      <b-card-header
        class="d-flex justify-content-between align-items-center card_title"
      >
        <h3 class="bold">Executive Network</h3>
        <div class="edit__icon">
          <!-- <img class="edit__icon" src="./../../assets/images/logo/bold.png" alt="" /> -->
          <b-img
            v-b-modal.modal-edit-executive
            v
            class="ml-auto"
            src="./../../assets/images/logo/bold.png"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <div class="row pl-2 pr-2">
          <div class="desiredCtc col-lg-6 col-sm-6">
            <div class="mb-2">
              <span class="mb-1">Desired CTC:</span>
              <h4 class="text-primary pt-1">
                ${{ user.candidate.desired_annual_salary }}
              </h4>
            </div>
            <div class="mb-2 pt-1">
              <span class="mb-1">Current CTC:</span>
              <h5 class="pt-1">${{ user.candidate.current_annual_salary }}</h5>
            </div>
          </div>
          <div class="desiredCtc col-lg-6 col-sm-6">
            <div class="mb-2">
              <span class="">Preferred Location:</span>
              <h4 class="pt-1">
                <span
                  v-for="(country, index) in user.candidate.proffered_location"
                  :key="country.id"
                >
                  {{ country.name }}
                  <span
                    v-if="user.candidate.proffered_location.length - 1 > index"
                    >,</span
                  >
                </span>
              </h4>
            </div>
            <div class="">
              <div class="pb-1">
                <span class="">Ready to Relocate?</span>
              </div>
              No
              <b-form-checkbox checked="true" name="check-button" switch inline>
                Yes
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      v-if="
        !user.candidate.desired_annual_salary &&
        !user.candidate.current_annual_salary
      "
      class="bg text-white"
    >
      <b-card-body>
        <div>
          <h3 class="m-0 text-white">Executive Network</h3>
          <h4 class="text-white mt-2">
            Would you like VEP to contact you for full time job oppurtunities ?
          </h4>
        </div>
        <b-button v-b-modal.modal-edit-executive class="mt-1" variant="dark">
          Get Started
        </b-button>
      </b-card-body>
    </b-card>
    <!-- modal vertical center -->
    <b-modal
      id="modal-edit-executive"
      centered
      title="Edit Executive Details"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok="handleExecutiveForm"
    >
      <div class="form">
        <b-form-group
          label="Do you want to join as an Executive?"
          label-for="Do you want to join as an Executive?"
        >
          <div class="d-flex">
            <b-form-radio
              v-model="executiveForm.executive"
              name="some-radios"
              value="true"
              class="mr-5"
            >
              Yes
            </b-form-radio>
            <b-form-radio
              v-model="executiveForm.executive"
              name="some-radios"
              value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-form-group>
        <b-form-group
          label="CCurrent CTC ( in USD )"
          label-for="Current CTC ( in USD )"
          class="mt-2"
        >
          <b-form-input
            id="current_ctc"
            v-model="executiveForm.currentSalary"
            placeholder="Enter currently pay per annum"
          />
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          class="px-1 mb-2"
          >Currency Converter
        </b-button>

        <b-form-group
          label="Desire CTC ( in USD )"
          label-for="Desire CTC ( in USD )"
        >
          <b-form-input
            id="desire_ctc"
            v-model="executiveForm.desiredSalary"
            placeholder="Enter currently pay per annum"
          />
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          class="px-1 mb-2"
          >Currency Converter
        </b-button>
        <b-form-group label="Preferred Location" label-for="Preferred Location">
          <v-select
            v-model="executiveForm.countries"
            multiple
            label="title"
            :options="countries"
            :get-option-label="getSelected"
          />
        </b-form-group>
        <b-form-group label="Ready to Relocate?" label-for="Ready to Relocate?">
          No
          <b-form-checkbox
            v-model="executiveForm.relocated"
            name="check-button"
            switch
            inline
          >
            Yes
          </b-form-checkbox>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BNavbar,
  BNavbarToggle,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BFormGroup,
    BCardHeader,
    BMedia,
    BFormInput,

    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BCard,
    BButton,
    BImg,
    BNavbar,
    BFormRadio,
    BNavbarToggle,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      executiveForm: {
        currentSalary: store.state.auth.user.candidate.current_annual_salary,
        desiredSalary: store.state.auth.user.candidate.desired_annual_salary,
        countries: store.state.auth.user.candidate.proffered_location,

        executive: true,
        relocated: store.state.auth.user.candidate.relocated === 1,
      },

      countries: store.state.utilities.countries,
    };
  },
  computed: {
    user() {
      return store.state.auth.user;
    },
  },
  created() {
    store.dispatch('utilities/countries');
  },
  methods: {
    handleExecutiveForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitExecutiveForm();
    },
    submitExecutiveForm() {
      store.dispatch('auth/fullTimeForm', this.executiveForm);
    },
    getSelected(option) {
      return (option && option.name) || '';
    },
  },
};
</script>
<style lang="scss" scoped>
.progress__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (min-width: 500px) {
  .form {
    margin: 20px 100px;
  }
}
.bg {
  background-color: #495973;
}
</style>
