<template>
  <div>
    <b-card class="">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <b-card-title class="ml-25 my-auto">
          <h3 class="bold">Experience</h3>
        </b-card-title>
        <b-button
          v-b-modal.modal-experience
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="getExperienceOnStateNew()"
        >
          Add New Experience
        </b-button>
      </div>

      <b-card
        v-for="(experience, i) in experiences"
        :key="i"
        class="thin-border"
      >
        <div class="company__title">
          <h6>{{ experience.company }}</h6>

          <div class="edit__icon">
            <b-img
              v-b-modal.modal-experience
              class="ml-auto mr-2"
              src="./../../assets/images/logo/bold.png"
              @click="getExperienceOnState(experience)"
            />
            <feather-icon
              size="30"
              icon="TrashIcon"
              @click="deleteExperience(experience)"
            />
          </div>
        </div>
        <p>
          {{ experience.job_responsbility }}
        </p>
        <p>{{ getUserDate(experience.from) }}</p>

        <!-- timeline -->
        <b-card-body>
          <app-timeline>
            <app-timeline-item variant="primary">
              <div
                class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
              >
                <h6 class="bold">
                  {{ experience.designation }}
                </h6>
              </div>
              <span
                >{{ experience.from }} -
                {{ experience.to ? experience.to : "Present" }} -
                {{ getExperienceDate(experience.from, experience.to) }}</span
              >
              <br />
              <span>{{ experience.city }} {{ experience.country }}</span>
              <b-media no-body class="mt-1">
                <p class="bold">
                  {{ experience.responsibility }}
                </p>
              </b-media>
            </app-timeline-item>
          </app-timeline>
          <!--/ timeline -->
          <div style="display: flex; align-items: center" class="my-1">
            <h6 class="m-0">Industry :</h6>
            <div style="display: grid" class="ml-2">
              <span
                v-for="(industry, i) in JSON.parse(experience.industry)"
                :key="i"
              >
                {{ industry.name }}
              </span>
            </div>
          </div>
          <div style="display: flex; align-items: center" class="my-1">
            <h6 class="m-0">Function :</h6>
            <div style="display: grid" class="ml-2">
              <span
                v-for="(func, i) in JSON.parse(experience.function)"
                :key="i"
              >
                {{ func.name }}
              </span>
            </div>
          </div>
          <div style="display: flex; align-items: center" class="my-1">
            <h6 class="m-0">Sub Function :</h6>
            <div style="display: grid" class="ml-2">
              <span
                v-for="(func, i) in JSON.parse(experience.sub_function)"
                :key="i"
              >
                {{ func.name }}
              </span>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-card>

    <!-- modal vertical center -->
    <b-modal
      id="modal-experience"
      ref="modal-experience"
      centered
      title="Add/Edit Experience"
      cancel-variant="outline-secondary"
      ok-title="Save Details"
      size="lg"
      @ok="handleExperienceForm"
    >
      <div class="pl-lg-5 pr-lg-5 ml-lg-3 mr-lg-3">
        <b-form-group label="Designation" label-for="designation">
          <b-form-input
            id="designation"
            v-model="experienceForm.designation"
            placeholder="Enter Designation"
          />
        </b-form-group>
        <b-form-group label="Company" label-for="company">
          <b-form-input
            id="company"
            v-model="experienceForm.company"
            placeholder="Enter Company"
          />
        </b-form-group>
        <b-form-group label="Industry" label-for="industry">
          <v-select
            v-model="experienceForm.industry"
            multiple
            :options="industries"
            :get-option-label="getSelected"
          />
        </b-form-group>
        <b-form-group label="Function" label-for="function">
          <v-select
            id="function"
            v-model="experienceForm.function"
            multiple
            label="name"
            :options="functions"
            :get-option-label="getSelected"
          />
        </b-form-group>
        <b-form-group class="mb-3" label="Sub-Function" label-for="subFunction">
          <v-select
            id="subFunction"
            v-model="experienceForm.sub_function"
            multiple
            label="name"
            :options="sub_functions"
            :get-option-label="getSelected"
          />
        </b-form-group>
        <b-form-group
          class="mb-3"
          label="Select Your Employment Type"
          label-for="empType"
        >
          <b-form-radio-group
            id="empType"
            v-model="experienceForm.employment_type"
            :options="empTypeOptions"
            :get-option-label="getSelected"
          />
        </b-form-group>
        <b-form-group
          class="mb-2"
          label="Are You Presently Working Here?"
          label-for="isPresentWork"
        >
          <b-form-radio-group
            id="isPresentWork"
            v-model="experienceForm.is_present"
            :options="empStrengthOptions"
          />
        </b-form-group>
        <div class="row mb-2">
          <div class="col-lg-6">
            <label for="startDate">From</label>
            <b-form-datepicker
              id="startDate"
              v-model="experienceForm.from"
              placeholder="From"
              class="mb-1"
            />
          </div>

          <div class="col-lg-6" v-if="experienceForm.is_present === 'false'">
            <label for="endDate">To</label>
            <b-form-datepicker
              id="endDate"
              v-model="experienceForm.to"
              placeholder="To"
              class="mb-1"
            />
          </div>
        </div>
        <b-form-group label="Job Responsibility" label-for="jobRes">
          <b-form-textarea
            id="jobRes"
            v-model="experienceForm.responsibility"
            placeholder="Textarea"
            rows="5"
          />
        </b-form-group>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group label="Country" label-for="country">
              <b-form-input
                v-if="typeof experienceForm.country === 'string'"
                id="city"
                v-model="experienceForm.country"
                disabled
              />

              <v-select
                v-else
                v-model="experienceForm.country"
                label="title"
                :options="countries"
                :get-option-label="getSelected"
              />
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group label="City" label-for="city">
              <b-form-input id="city" v-model="experienceForm.city" />
            </b-form-group>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import {
  BButton,
  BCard,
  BCardBody,
  BCardTitle,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BImg,
  BMedia,
  VBTooltip,
} from "bootstrap-vue"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
/* eslint-disable global-require */
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BImg,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BFormRadioGroup,

    vSelect,
  },

  data() {
    return {
      experienceForm: {
        key: null,
        designation: null,
        company: null,
        industry: null,
        function: null,
        sub_function: null,
        employment_type: null,
        is_present: "false",
        from: null,
        to: null,
        responsibility: null,
        country: null,
        city: null,
        id: null,
      },
      empStrengthOptions: [
        { text: "Yes", value: "true" },
        { text: "No ", value: "false" },
      ],
      countries: store.state.utilities.countries,
      expertise: store.state.utilities.expertise,
      industries: store.state.utilities.industries,
      functions: store.state.utilities.functions,
      sub_functions: store.state.utilities.subFunctions,

      empTypeOptions: [
        { text: "Full Time", value: "fulltime" },
        { text: "Part Time", value: "parttime" },
        { text: "Self Employed", value: "selfemployed" },
        { text: "Internship", value: "internship" },
      ],
    }
  },
  computed: {
    user() {
      return store.state.auth.user
    },
    experiences() {
      return store.state.auth.user.candidate.experiences
    },
  },
  created() {
    store.dispatch("utilities/countries")
    store.dispatch("utilities/expertise")
    store.dispatch("utilities/industry")
    store.dispatch("utilities/candidate_functions")
    store.dispatch("utilities/sub_functions")
  },
  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    getSelectedPresent(option) {
      return (option && option.value) || ""
    },
    getUserDate(from) {
      const now = moment(new Date(), "MM-DD-YYYY")
      const then = moment(from)

      return moment.duration(then.diff(now)).humanize(true)
    },
    getExperienceDate(from, to) {
      if (to) {
        return moment.duration(moment(from).diff(moment(to))).humanize(true)
      }

      const present = new Date()
      return moment.duration(moment(from).diff(moment(present))).humanize(true)
    },
    getExperienceOnStateNew() {
      this.experienceForm.designation = ""
      this.experienceForm.company = ""
      this.experienceForm.industry = ""
      this.experienceForm.function = ""
      this.experienceForm.sub_function = ""
      this.experienceForm.employment_type = ""
      this.experienceForm.from = ""
      this.experienceForm.to = ""
      this.experienceForm.responsibility = ""
      this.experienceForm.country = null
      this.experienceForm.city = ""
      this.experienceForm.is_present = "false"
    },
    getExperienceOnState(experience) {
      this.experienceForm.id = experience.id
      this.experienceForm.designation = experience.designation
      this.experienceForm.company = experience.company
      this.experienceForm.industry = JSON.parse(experience.industry)
      this.experienceForm.function = JSON.parse(experience.function)
      this.experienceForm.sub_function = JSON.parse(experience.sub_function)
      this.experienceForm.employment_type = experience.employment_type
      this.experienceForm.from = experience.from
      this.experienceForm.to = experience.to
      this.experienceForm.responsibility = experience.job_responsbility
      this.experienceForm.country = experience.country
      this.experienceForm.city = experience.city
      this.experienceForm.is_present = experience.presentely_working
    },

    handleExperienceForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitExperienceForm()
    },
    submitExperienceForm() {
      const formData = {
        id: this.experienceForm.id,
        designation: this.experienceForm.designation,
        company: this.experienceForm.company,
        industry: this.experienceForm.industry,
        function: this.experienceForm.function,
        sub_function: this.experienceForm.sub_function,
        employment_type: this.experienceForm.employment_type,
        from: this.experienceForm.from,
        to: this.experienceForm.to,
        responsibility: this.experienceForm.responsibility,
        country: this.experienceForm?.country?.name
          ? this.experienceForm.country.name
          : this.experienceForm.country,
        city: this.experienceForm.city,
        is_present: this.experienceForm.is_present,
      }
      store
        .dispatch("candidateProfile/profileExperienceDetails", formData)
        .then(() => {
          this.$emit("custom-event-refresh-experience")
        })
      this.$nextTick(() => {
        this.$bvModal.hide("modal-experience")
      })
    },
    clearState() {
      this.experienceForm.designation = null
      this.experienceForm.company = null
      this.experienceForm.industry = null
      this.experienceForm.function = null
      this.experienceForm.sub_function = null
      this.experienceForm.employment_type = null
      this.experienceForm.from = null
      this.experienceForm.to = null
      this.experienceForm.responsibility = null
      this.experienceForm.country = null
      this.experienceForm.city = null
    },

    deleteExperience(data) {
      const formData = {
        id: data.id,
      }
      store.dispatch(
        "candidateProfile/profileExperienceDetailsDelete",
        formData
      )
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style>
.bold {
  font-weight: bold;
}
.thin-border {
  border: 0.5px solid lightgray;
  border-radius: 6px;
}
.company__title {
  display: flex;
  justify-content: space-between;
}
.edit__icon :hover {
  cursor: pointer;
}
.addAnotherContainer {
  display: flex;
  justify-content: center;
}
.addAnother {
  color: rgb(100, 151, 233);
}
</style>
