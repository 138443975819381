<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h3 class="bold">Sub Function</h3>
      <!-- <div class="edit__icon">
        <b-img
          v-if="user_sub_functions"
          v-b-modal.modal-sub-function
          src="./../../assets/images/logo/bold.png"
        />
        <b-button
          v-if="!user_sub_functions"
          v-b-modal.modal-sub-function
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
        >
          Add Function
        </b-button>
      </div> -->
    </b-card-header>

    <b-card-body v-if="view_sub_funcs">
      <b-button
        v-for="(candidate_sub_function, index) in view_sub_funcs"
        :key="index"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-2 mr-2"
      >
        {{ candidate_sub_function }}
      </b-button>
    </b-card-body>
    <!-- modal vertical center -->
    <b-modal
      id="modal-sub-function"
      centered
      title="Edit/Add Sub Function"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok="handleSubFunctionForm"
    >
      <h6>Sub Function</h6>
      <v-select
        v-model="subFunctionForm.sub_functions"
        multiple
        label="title"
        :options="subFunctions"
        :get-option-label="getSelected"
      />
    </b-modal>
  </b-card>
</template>

<script>
import store from "@/store"
import { BButton, BCard, BCardBody, BCardHeader, BImg } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"

export default {
  components: {
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      subFunctionForm: {
        sub_functions: JSON.parse(
          store.state.auth.user.candidate.sub_functions
          // store.state.auth.user.candidate.sub_functions
        ),
      },

      subFunctions: store.state.utilities.subFunctions,
      view_sub_funcs: [],
    }
  },
  computed: {
    user_sub_functions() {
      return JSON.parse(store.state.auth.user.candidate.sub_functions)
      // return JSON.parse(store.state.auth.user.candidate.sub_functions);
    },
  },
  created() {
    // store.dispatch("utilities/sub_functions")
  },
  mounted() {
    this.getExperience()
  },
  methods: {
    getExperience() {
      const funcData = store.state.auth.user.candidate.experiences
      const funcArray = []
      funcData.forEach((element) => {
        funcArray.push(...JSON.parse(element.sub_function))
      })
      this.view_sub_funcs = Array.from(
        new Set(funcArray.map((item) => item.name))
      )
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
    handleSubFunctionForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.submitSubFunctionForm()
    },
    submitSubFunctionForm() {
      store.dispatch("candidateProfile/updateResume", this.subFunctionForm)
    },
  },
}
</script>
<style lang="scss" scoped>
.progress__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
